import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import GrillIcon from "../components/svg/grill";
import '../assets/css/grill.css';
import Card from "../components/card";
import GrillProgram from '../config/contents/grill.json';

const GrillPage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, site, boilerPlateImage } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.grill;
  // the current day number from 0-6 of sunday-saturday respectively
  const currentDayIndex = (new Date()).getDay();
  /**
   * display a tan after clicking tab link
   * @param evnt click event
   */
  const onToggleTab = (evnt: React.MouseEvent<HTMLAnchorElement>) => {
    evnt.preventDefault();
    const { currentTarget } = evnt;
    const { href } = currentTarget.dataset;
    let targetElement, previousTab, activeTabToggle;
    if (typeof window !== 'undefined') {
      targetElement = document?.querySelector(href as string);
      previousTab = document.querySelector(`.tab-pane.active`);
      activeTabToggle = document.querySelector(`.tab__toggle.active`);

      if (targetElement && !targetElement?.classList?.contains('active')) {
        // add/remove class to selected tabs
        targetElement?.classList?.add('show');
        targetElement?.classList?.add('active');
        previousTab?.classList?.remove('show');
        previousTab?.classList?.remove('active');
        // change the state of tab menu link
        activeTabToggle?.classList?.remove('active');
        currentTarget?.classList.add('active');
      }
    }
    return false;
  }

  return (
    <Layout className='home__page relative overflow-hidden' headerClassName='navbar-wrapper'>

      <SEO title={title} description={description} path={path} image={pageImage?.childImageSharp?.fluid?.src} />

      <section className='main__block position-relative'>
        <div className="container page__container text-white position-relative z-index_1">
          <div className="container__row">
            <h1 className="p-0 main-title col font-weight-normal text-uppercase mb-2 mb-md-5">
              <GrillIcon className='title__icon mr-3' />
              Grill
            </h1>
            <p className="m-0">
              Soon
            </p>
          </div>

          <div className="container__row my-3 my-md-5 pt-1">

            <ul className="nav nav-pills custom__nav-pills mt-3 mb-5 w-100 d-flex justify-content-center" id="pills-tab" role="tablist">
              {
                Object.keys(GrillProgram).map((day, index) => {
                  const activeDayClasses = index === currentDayIndex ? `active` : '';

                  return (
                    <li key={day} className={`nav-item position-relative`} role="presentation">
                      <a
                        href={`#pills-${day}-tab`}
                        data-href={`#pills-${day}-tab`}
                        className={`tab__toggle nav-link font-weight-light text-uppercase ${activeDayClasses}`}
                        id={`pills-${day}-link`}
                        onClick={onToggleTab}
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true">
                        {day}
                      </a>
                    </li>
                  )
                })
              }
            </ul>

            <div className="tab-content" id="pills-tabContent">
              {
                Object.keys(GrillProgram).map((day, index) => {
                  // @ts-ignore
                  const currentDayPrograms = GrillProgram[day];
                  const displayClasses = index === currentDayIndex ? `show active` : '';

                  return (
                    <div key={`pills-${day}-tab`} className={`tab-pane fade ${displayClasses}`} id={`pills-${day}-tab`} role="tabpanel" aria-labelledby="pills-home-tab">
                      <div className='d-flex flex-wrap flex-column flex-md-row'>
                        {
                          currentDayPrograms.map(({ title, startTime, endTime, description, slug }: any) => (
                            <Card
                              key={`${day}-${slug}`}
                              className='mr-0 mr-md-3 mb-3'
                              title={title}
                              startTime={startTime}
                              endTime={endTime}
                              imageSrc={boilerPlateImage?.childImageSharp?.fluid}>
                              {description}
                            </Card>
                          ))
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>

          </div>
        </div>
      </section>

    </Layout>
  );
}

export const query = graphql`
  query grillQuery {
    site {
      siteMetadata {
        lang
        pages {
          grill {
            title
            path
            description
          }
        }
      }
    }

    pageImage: file(relativePath: { eq: "algone-bg-logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    boilerPlateImage: file(relativePath: { eq: "nos-contacts/bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default GrillPage;
