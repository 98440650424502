import React from 'react';
import Image from "../image";

import './style.css';

interface CardPropsType {
  title: string;
  startTime: string;
  endTime?: string;
  imageSrc: string;
  children?: React.ReactNode;
  className?: string;
  headerClassName?: string;
}

/**
 * Card component
 * @name Card
 * @description display a custom card
 */
export default function Card({ title, endTime, startTime, children, imageSrc, className, headerClassName }: CardPropsType) {
  return (
    <div className={`card__block position-relative ${className}`}>
      <div className={`card__header font-weight-light mb-md-4 ${headerClassName}`}>
        <div className='card__time position-relative mb-4'>
          <time dateTime={startTime}>{startTime}</time>
          {
            endTime && (
              <>
                {` - `}<time dateTime={endTime}>{endTime}</time>
              </>
            )
          }
        </div>
        <h3 className="card__title font-weight-bold text-uppercase">{title}</h3>
      </div>
      <div className="card__content d-flex flex-column flex-sm-row">
        <div className="card__image-container overflow-hidden mr-0 mr-sm-3 mb-3 mb-sm-0 flex_1">
          <Image src={imageSrc} alt={title} className='h-100' />
        </div>
        {
          children && (
            <div className="card__description ml-0 ml-sm-3 flex_1 font-weight-normal">
              {children}
            </div>
          )
        }
      </div>
    </div >
  )
}
